import AppsIcon from "@mui/icons-material/Apps";
import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Link, Menu, TextField, Checkbox, FormControlLabel, Card, CardContent, Box, Typography, CircularProgress } from '@mui/material';
import React, { useEffect } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { collection, getDocs, where, query, doc, getDoc, getCountFromServer } from 'firebase/firestore';

//Icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import GoogleIcon from '@mui/icons-material/Google'; // Replace with your actual icons
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import PlaceIcon from '@mui/icons-material/Place';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ShopIcon from '@mui/icons-material/Shop';
import StorefrontIcon from '@mui/icons-material/Storefront';
import YouTubeIcon from '@mui/icons-material/YouTube';

// Stores
import useAuthStore from '../../stores/authStore';

export default function ServiceMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [openSignup, setOpenSignup] = React.useState(false);

  const setUser = useAuthStore(state => state.setUser);
  const createUser = useAuthStore(state => state.createUser);
  const signIn = useAuthStore(state => state.signIn);
  const logout = useAuthStore(state => state.logout);

  const [loginEmail, setLoginEmail] = React.useState('');
  const [loginPassword, setLoginPassword] = React.useState('');

  const [signupFirstName, setSignupFirstName] = React.useState('');
  const [signupLastName, setSignupLastName] = React.useState('');
  const [signupEmail, setSignupEmail] = React.useState('');
  const [signupPassword, setSignupPassword] = React.useState('');
  const [signupUsername, setSignupUsername] = React.useState('');
  const [termsAgreed, setTermsAgreed] = React.useState(false);

  const [gameStats, setGameStats] = React.useState([]);
  const [statsLoading, setStatsLoading] = React.useState(false);
  const [menuOpened, setMenuOpened] = React.useState(false);

  const fetchGameStats = async () => {
    setStatsLoading(true);
    const gameStatsTemp = [];

    // Fetch all games the user participated in
    const gameDocs = await getDocs(collection(db, 'games'));
    gameDocs.forEach((gameDoc) => {
      const gameData = gameDoc.data();
      gameStatsTemp.push({
        gameId: gameDoc.id,
        ...gameData
      });
    });

    // Fetch the user's answers for each game
    for (const gameStat of gameStatsTemp) {
      const userAnswersCollection = collection(db, 'games', gameStat.gameId, 'userAnswers');
      const allAnswers = query(userAnswersCollection, where('userId', '==', auth.currentUser.uid));
      const correctAnswers = query(userAnswersCollection, where('userId', '==', auth.currentUser.uid), where('wasCorrect', '==', true));
      const userAnswerDocs = await getCountFromServer(allAnswers);
      const correctAnswerDocs = await getCountFromServer(correctAnswers);
      let correctCount = correctAnswerDocs.data().count;
      let totalCount = userAnswerDocs.data().count;

      gameStat.correctCount = correctCount;
      gameStat.totalCount = totalCount;
      gameStat.accuracy = totalCount === 0 ? 0 : (correctCount / totalCount) * 100;
    }

    setGameStats(gameStatsTemp);
    setStatsLoading(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const success = await signIn(e, loginEmail, loginPassword);
    if (success) {
      setUser({ email: loginEmail });
      handleLoginClose();
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!termsAgreed) {
      alert("You must agree to the terms and conditions before signing up.");
      return;
    }
    const success = await createUser(e, signupEmail, signupPassword, signupFirstName, signupLastName, signupUsername, termsAgreed);
    if (success) {
      setUser({ email: signupEmail });
      handleSignupClose();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    !menuOpened && auth.currentUser &&
      fetchGameStats();
    setMenuOpened(true);

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoginOpen = () => {
    setOpenLogin(true);
  };

  const handleLoginClose = () => {
    setOpenLogin(false);
  };

  const handleSignupOpen = () => {
    setOpenSignup(true);
  };

  const handleSignupClose = () => {
    setOpenSignup(false);
  };

  const handleLogout = () => {
    logout();
    setUser(null);
    handleClose();
    // set user to null
    // Add other logout related tasks here as necessary
  };

  const menuItems = [
    { icon: <AccountCircleIcon />, link: 'https://myaccount.google.com/', text: 'Account' },
    { icon: <GoogleIcon />, link: 'https://www.google.com/', text: 'Search' },
    { icon: <StorefrontIcon />, link: 'https://www.google.com/intl/en_za/business/', text: 'Business' },
    { icon: <PlaceIcon />, link: 'https://www.google.com/maps/', text: 'Maps' },
    { icon: <YouTubeIcon />, link: 'https://www.youtube.com/', text: 'Youtube' },
    { icon: <ShopIcon />, link: 'https://play.google.com/store', text: 'Play' },
    { icon: <NewspaperIcon />, link: 'https://news.google.com/home', text: 'News' },
    { icon: <MailOutlineIcon />, link: 'https://mail.google.com/mail', text: 'Gmail' },
    { icon: <PhotoCameraFrontIcon />, link: 'https://meet.google.com/', text: 'Meet' },
    { icon: <QuestionAnswerIcon />, link: 'https://mail.google.com/chat/', text: 'Chat' },
    { icon: <AddToDriveIcon />, link: 'https://drive.google.com/drive/', text: 'Drive' },
    { icon: <NoteAddIcon />, link: 'https://docs.google.com/spreadsheets/', text: 'Sheets' },
  ];

  // Function to chunk an array
  const chunkArray = (myArray, chunk_size) => {
    let results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }

  // Chunk the menu items into groups of 3
  const chunkedMenuItems = chunkArray(menuItems, 3);

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: 'white',
          '&:hover': {
            backgroundColor: 'grey',

          },
          // Add a border  to the button

        }}>
        <AppsIcon style={{ color: 'white' }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            width: 350,
            borderRadius: '20px',
          },
        }}

      >
        {chunkedMenuItems.map((rowItems, rowIndex) => (
          <div key={`row-${rowIndex}`} style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginBottom: '20px',

          }}>
            {rowItems.map((item, index) => (
              <div key={`item-${index}`} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <a href={item.link} target="_self" style={{ textDecoration: 'none', color: 'black' }}>
                  <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    sx={{
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'grey',
                      },
                    }}
                  >
                    {React.cloneElement(item.icon, { style: { color: 'black', fontSize: '2em' } })}
                  </IconButton>
                </a>

                <Link style={{
                  color: 'black',
                  textDecoration: 'none',
                  display: 'block',
                }} href={item.link} target="_self">{item.text}</Link>

              </div>
            ))}
          </div>
        ))}


        {!statsLoading && auth.currentUser && gameStats.map((gameStat, index) => (
          <>
            <Divider
              // Make thicker
              style={{
                height: '2px', backgroundColor: 'grey', marginBottom: '20px'
              }}
            />
            <Box key={`gameStat-${index}`} sx={{ marginBottom: '1em' }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" color="text.primary">
                    {gameStat.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Answers: {gameStat.totalCount}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Correct Answers: {gameStat.correctCount}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Accuracy: {gameStat.accuracy.toFixed(2)}%
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </>
        ))
        }
        {statsLoading && auth.currentUser && <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '20px',
          paddingRight: '20px',
          marginBottom: '20px'
        }}>
          
          <CircularProgress />

        </div>
        }

        <Divider
          // Make thicker
          style={{
            height: '2px', backgroundColor: 'grey', marginBottom: '20px'
          }}
        />

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '20px',
          paddingRight: '20px',
          marginBottom: '20px'
        }}>
          {auth.currentUser ?
            <Button variant="outlined" style={{ marginRight: '10px' }} onClick={handleLogout}>Logout</Button>
            :
            <>
              {/* Title that says "Keepe track of your score" */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  flex: 1,
                  // alignItems: 'center',
                }}
              >

                <Typography variant="h6" color="text.primary" paddingBottom={3} alignSelf={'center'}>
                  Keep track of your score
                </Typography>

                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'space-around',
                }}>

                  <Button variant="outlined" style={{ marginRight: '10px' }} onClick={handleLoginOpen}>Login</Button>
                  <Button variant="contained" onClick={handleSignupOpen}>Sign Up</Button>

                </div>
              </div>
            </>
          }
        </div>


      </Menu>

      <Dialog open={openLogin} onClose={handleLoginClose}>
        <DialogTitle style={{ color: 'black' }}>Login</DialogTitle>
        <DialogContent>
          <form onSubmit={handleLogin}>
            <TextField autoFocus margin="dense" id="email" label="Email Address" type="email" fullWidth value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} />
            <TextField margin="dense" id="password" label="Password" type="password" fullWidth value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} />
            <Button type="submit">Login</Button>
          </form>
        </DialogContent>
      </Dialog>

      {/* Signup Dialog */}
      <Dialog open={openSignup} onClose={handleSignupClose}>
        <DialogTitle style={{ color: 'black' }}>Sign Up</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSignup}>
            <TextField autoFocus margin="dense" id="firstName" label="First Name" type="text" fullWidth value={signupFirstName} onChange={(e) => setSignupFirstName(e.target.value)} />
            <TextField margin="dense" id="lastName" label="Last Name" type="text" fullWidth value={signupLastName} onChange={(e) => setSignupLastName(e.target.value)} />
            <TextField margin="dense" id="username" label="Username" type="text" fullWidth value={signupUsername} onChange={(e) => setSignupUsername(e.target.value)} />
            <TextField margin="dense" id="email" label="Email Address" type="email" fullWidth value={signupEmail} onChange={(e) => setSignupEmail(e.target.value)} />
            <TextField margin="dense" id="password" label="Password" type="password" fullWidth value={signupPassword} onChange={(e) => setSignupPassword(e.target.value)} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAgreed}
                  onChange={(e) => setTermsAgreed(e.target.checked)}
                  name="termsAgreed"
                  color="primary"
                />
              }
              label={
                <span>
                  I agree to the {' '}
                  <RouterLink to="/terms-and-conditions" style={{ textDecoration: 'none', color: '#3f51b5' }}>
                    Terms and Conditions
                  </RouterLink>
                </span>
              }

            />
            <Button type="submit">Sign Up</Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
