import React, { useState, useEffect } from 'react';
import { countries } from '../../data/countries.js';
import useSearchStore from '../../stores/useSearchStore.js';
import Countdown from './Countdown.js';

import { sendEvent } from '../../utils/analytics/googleAnalyticFunctions.js';

import {addFlagAnswer} from '../../utils/firestore/addAnswer.js';

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

function getTwoCountries() {
    const index1 = getRandomInt(countries.length);
    let index2 = getRandomInt(countries.length);

    while (index1 === index2) {
        index2 = getRandomInt(countries.length);
    }

    const country1 = countries[index1];
    const country2 = countries[index2];

    const rightAnswer = getRandomInt(2) === 0 ? country1 : country2;

    const options = [country1, country2];

    return {
        rightAnswer,
        options,
    };
}

const FlagFromCountryQuiz = ({ onFlagClick }) => {
    const [quizData, setQuizData] = useState(null);
    // const [selected, setSelected] = useState(null);
    const [message, setMessage] = useState('');
    const [showCountdown, setShowCountdown] = useState(false);

    const searchTerm = useSearchStore((state) => state.searchTerm);

    const question = `Select the flag of ${quizData ? quizData.rightAnswer.name : ''}`;
    const options = quizData ? quizData.options : [];
    const pageName = `${window.location.search.split('utm_source=')[1] ? `${" - "} ${window.location.search.split('utm_source=')[1].split('&')[0]}` : ''}`

    useEffect(() => {
        const data = getTwoCountries();
        setQuizData(data);
    }, []);

    const handleButtonClick = (country) => {
        // setSelected(country);

        addFlagAnswer('aGvULqZTE4kmUGfSy70r', quizData.rightAnswer.code, country.code === quizData.rightAnswer.code,
            country.code === quizData.rightAnswer.code ? quizData.options.filter(option => option.code !== quizData.rightAnswer.code)[0].code : country.code
        )

        if (country.code === quizData.rightAnswer.code) {
            setMessage(`Correct!`);
            sendEvent(pageName, "user_engagement", "correctCountryChosen", "button_press");
            onFlagClick();
        } else {
            setMessage(`Better luck next time.`);
            sendEvent(pageName, "user_engagement", "wrongCountryChosen", "button_press");
            onFlagClick();
            if (searchTerm) {
                setShowCountdown(true);
                setTimeout(() => {
                    window.location.href = `https://www.google.com/search?q=${searchTerm}`;
                    setShowCountdown(false);
                }, 3000);
            }
        }

    };

    if (!quizData) return null;

    return (
        <>
            <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', borderWidth: '1px', borderColor: 'white' }}>
                <p style={{
                    marginBottom: '20px',
                    color: 'white',
                    fontSize: '1.5em',
                    fontWeight: 'bold'
                }}>
                    {question}
                </p>
                {!message ? (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
                        {options.map((option, index) => (

                            <img src={`https://flagsapi.com/${option.code}/flat/64.png`}
                                style={{ backgroundColor: '#888888', borderRadius: '10px', cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px' }}
                                // width and height are set to 64px in the url
                                width={64}
                                height={64}
                                onMouseEnter={(e) => { e.target.style.backgroundColor = '#a8a8a8'; }}
                                onMouseLeave={(e) => { e.target.style.backgroundColor = '#888888'; }}
                                key={index}
                                onClick={() => handleButtonClick(option, index)}
                                alt='flag'
                            />
                        ))}
                    </div>
                ) : (
                    <>
                        <p style={{ marginTop: '20px', color: 'white', fontSize: '1.5em', fontWeight: 'bold' }}>{message}</p>
                        {showCountdown && <Countdown />}
                    </>
                )}
            </div>
        </>
    );
};

export default FlagFromCountryQuiz;