import React from 'react';
import { Link } from 'react-router-dom';

import './Privacy-Policy.css';
import { Helmet } from 'react-helmet';



import { sendPageView } from '../../utils/analytics/googleAnalyticFunctions';

const PrivacyPolicy = () => {


    const pageName = `${window.location.search.split('utm_source=')[1] ? `${" - "} ${window.location.search.split('utm_source=')[1].split('&')[0]}` : ''}`

    // sendPageView(pageName);


    return (
        <div className="container">
            <Helmet>
                <title>New Tab - Privacy Policy</title>
            </Helmet>
            <Link to="/" className="back-button">
                Back to Home
            </Link>

            <h1>Privacy Policy</h1>
            <h3>Effective Date: 30 June 2023</h3>

            <h2>Our Commitment to Privacy</h2>
            <p>We value your privacy and are committed to protecting your personal information. This privacy policy explains how we collect, use, and disclose information when you use our product Knew Tab. By using our product, you consent to the practices described in this policy.</p>

            <h2>Information We Collect</h2>
            <h3>Personal Information:</h3>
            <p>Types of personal information we collect: Name & email address</p>
            <p>How we collect this information:
                <ul>
                    <li>When you sign up for our product</li>
                    <li>When you contact us directly</li>
                </ul>
            </p>

            <h3>Usage Data:</h3>
            <p>Types of usage data we collect:
                <ul>
                    <li>Device information (e.g., device type, operating system, browser type, etc.)</li>
                    <li>Log data (e.g., access times, pages viewed, IP address, etc.)</li>
                    <li>Location data (e.g., country)</li>
                </ul>
            </p>
            <p>How we collect this data:
                <ul>
                    <li>When you use our product</li>
                    <li>When you contact us directly</li>
                </ul>

            </p>

            <h2>How We Use Information</h2>
            <p>Provide and Improve Our Product: We use the collected information to provide and enhance the functionality of our product.</p>
            <p>Communication: We may use personal information to communicate with users, such as responding to inquiries or sending product-related updates.</p>
            <p>Analytics and Performance: We may use usage data for analytical purposes, such as analyzing user behavior, trends, and preferences.</p>
            <p>Legal Compliance: We may use or disclose information to comply with applicable laws, regulations, or legal processes.</p>

            <h2>Data Sharing and Disclosure</h2>
            <p>Service Providers: We may share information with third-party service providers who assist in product operations and maintenance.</p>
            <p>Legal Requirements: We may disclose information if required to do so by law or in response to valid legal requests.</p>
            <p>Business Transfers: In the event of a merger, acquisition, or sale of assets, user information may be transferred as part of the transaction.</p>

            <h2>Data Security</h2>
            <p>Security Measures: We have implemented security measures to protect user information.</p>
            <p>Data Retention: We retain user information for 30 days and use criteria to determine retention periods.</p>

            <h2>User Rights and Choices</h2>
            <p>Access, Update, and Delete: Users can access, update, or delete their personal information by emailing us at knewtabsite@gmail.com.</p>
            <p>Marketing Communications: Users can opt-out of receiving marketing communications by pressing "Unsubscribe" in an email.</p>
            <p>Do Not Track Signals: We do not currently respond to "Do Not Track" signals from web browsers.</p>

            <h2>Changes to this Privacy Policy</h2>
            <p>Updates to the Policy: We may update this privacy policy from time to time. The effective date of the latest version is 30 June 2023.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions, concerns, or requests regarding this privacy policy or our data practices, please contact us at knewtabsite@gmail.com.</p>
        </div>
    );
};

export default PrivacyPolicy;
