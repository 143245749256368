import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuthStore from '../../stores/authStore';

// CSS
// import '../../css/AuthStack.css';
const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const signIn = useAuthStore(state => state.signIn);
  const signInWithFacebook = useAuthStore(state => state.signInWithFacebook);
  const [buttonHovered, setButtonHovered] = useState(false);

  return (
    <div className='auth__container'>
      <div>
        <h1 className='auth__heading'>Login to your account</h1>
        <p className='auth__byline'>
          Don't have an account yet?{' '}
          <Link to='/register' className='underline'>
            Register.
          </Link>
        </p>
      </div>

      <form onSubmit={async (e) => {
        // const success = await signIn(e, email, password);
        // if (success) navigate('/')
      }}>

        <div className='auth__formDiv'>
          <label className='auth__formHeading'>Email Address</label>
          <input onChange={(e) => setEmail(e.target.value)} className='auth__formInput' type='email' />
        </div>
        <div className='auth__formDiv'>
          <label className='auth__formHeading'>Password</label>
          <input onChange={(e) => setPassword(e.target.value)} className='auth__formInput' type='password' />
        </div>
        <button
          onMouseEnter={() => setButtonHovered(true)}
          onMouseLeave={() => setButtonHovered(false)}
          className={buttonHovered ? 'auth__buttonHovered' : 'auth__buttonNotHovered'}>
          Login
        </button>
        {/* Login with FB */}
        <button onClick={async (e) => {
          e.preventDefault();
          const success = await signInWithFacebook()
          if (success) navigate('/')
        }}
          className={'auth__buttonNotHovered'}>
          Login with Facebook
        </button>
      </form>
    </div>
  );
};

export default Login;
