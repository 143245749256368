import { useEffect, useRef } from 'react'

export default function Banner() {
    const banner = useRef();

    const atOptions = {
        key: 'bbfbe64edfdb111e8aadf222ec4d4959',
        format: 'iframe',
        height: 250,
        width: 300,
        params: {},
    }
    useEffect(() => {
    if (banner.current && !banner.current.firstChild) {
        const conf = document.createElement('script')
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `//www.profitabledisplaynetwork.com/${atOptions.key}/invoke.js`
        conf.text = `var atOptions = ${JSON.stringify(atOptions)}`;
        conf.type = "text/javascript";

        banner.current.appendChild(conf);
        banner.current.appendChild(script);
    }
}, [])

    return <div className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center" ref={banner}></div>
}
