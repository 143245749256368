import React from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import './Terms-And-Conditions.css';

const TermsAndConditions = () => {

    return (
        <div className="container">
            <Helmet>
                <title>Knew Tab - Terms and Conditions</title>
            </Helmet>
            <div className="terms-and-conditions">
                <h1>Terms and Conditions</h1>
                <p>Last updated: July 3, 2023</p>
                <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the Knew Tab mobile application (the "Service") operated by Knewtab.com ("us", "we", or "our").</p>

                <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>

                <h2>1. Accounts</h2>
                <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>

                <h2>2. Intellectual Property</h2>
                <p>The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Knewtab.com and its licensors.</p>

                <h2>3. Links To Other Web Sites</h2>
                <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Knewtab.com.</p>
                <p>Knewtab.com has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Knewtab.com shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

                <h2>4. Termination</h2>
                <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>

                <h2>5. Limitation Of Liability</h2>
                <p>In no event shall Knewtab.com, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

                <h2>6. Changes</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time.</p>

                <h2>7. Contact Us</h2>
                <p>If you have any questions about these Terms, please contact us.</p>
            </div>
            <Link to="/" className="back-button">
                Back to Home
            </Link>
        </div>
    );

};

export default TermsAndConditions;
