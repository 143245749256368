// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyALc8tn7uOM40i9w1DPTaZbS4XJpPs2NMY",
  authDomain: "knewtab-f7385.firebaseapp.com",
  projectId: "knewtab-f7385",
  storageBucket: "knewtab-f7385.appspot.com",
  messagingSenderId: "840527886234",
  appId: "1:840527886234:web:50ca255136b782e9a32c23",
  measurementId: "G-0FYYV5XVNE"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

export default app



