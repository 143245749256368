import { create } from "zustand";

import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut, signInWithPopup, FacebookAuthProvider
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, serverTimestamp, setDoc } from "firebase/firestore";



const useAuthStore = create((set) => ({
    user: {},
    setUser: user => set(state => ({ ...state, user })),
    createUser: async (e, email, password, firstName, lastName, username, termsAgreed) => {
        try {
            e.preventDefault();
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            await setDoc(doc(db, "users", user.uid, "private", 'contactInformation'), {
                email,
                firstName,
                lastName,
                termsAgreed,
                userRole: 'user',
            });
            await setDoc(doc(db, "users", user.uid, 'public', 'PublicProfile'), {
                uid: user.uid,
                createdTime: serverTimestamp(),
                updatedTime: serverTimestamp(),
                username,
            }); // A
        } catch (e) { console.log(e.message); alert(e.message); return false }
    },
    signIn: async (e, email, password) => { try { e.preventDefault(); await signInWithEmailAndPassword(auth, email, password); return true } catch (e) { console.log(e.message); alert(e.message); return false } },

    signInWithFacebook:
        async (e) => {
            const provider = new FacebookAuthProvider();
            // Get extra permissions
            provider.addScope('pages_read_engagement');
            provider.addScope('pages_manage_engagement');
            provider.addScope('read_insights');
            // e.preventDefault();

            try {
                await signInWithPopup(auth, provider);
                return true
            }

            catch (e) {
                console.log(e.message);
                alert(e.message);
                return false
            }

        },

    logout: (e) => { try { signOut(auth) } catch (e) { console.log(e.message); alert(e.message) } },
}));

export default useAuthStore;
