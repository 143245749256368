import React, { useState, useEffect } from 'react';
import { countries } from '../../data/countries.js';
import useSearchStore from '../../stores/useSearchStore.js';
import Countdown from './Countdown.js';

import { sendEvent } from '../../utils/analytics/googleAnalyticFunctions.js';

import {addFlagAnswer} from '../../utils/firestore/addAnswer.js';

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

function getTwoCountries() {
    const index1 = getRandomInt(countries.length);
    let index2 = getRandomInt(countries.length);

    while (index1 === index2) {
        index2 = getRandomInt(countries.length);
    }

    const country1 = countries[index1];
    const country2 = countries[index2];

    const rightAnswer = getRandomInt(2) === 0 ? country1 : country2;

    const options = [country1, country2];

    return {
        rightAnswer,
        options,
    };
}

const CountryFromFlagQuiz = ({ onFlagClick }) => {
    const [quizData, setQuizData] = useState(null);
    // const [selected, setSelected] = useState(null);
    const [message, setMessage] = useState('');
    const [showCountdown, setShowCountdown] = useState(false);

    const searchTerm = useSearchStore((state) => state.searchTerm);

    const question = `Name the country:`;
    const options = quizData ? quizData.options : [];
    const pageName = `${window.location.search.split('utm_source=')[1] ? `${" - "} ${window.location.search.split('utm_source=')[1].split('&')[0]}` : ''}`

    useEffect(() => {
        const data = getTwoCountries();
        setQuizData(data);
    }, []);

    const handleButtonClick = (country) => {
        // setSelected(country);

        addFlagAnswer('selLFHJzTYNexBEryV1B', quizData.rightAnswer.code, country.code === quizData.rightAnswer.code,
            country.code === quizData.rightAnswer.code ? quizData.options.filter(option => option.code !== quizData.rightAnswer.code)[0].code : country.code
        )
        if (country.code === quizData.rightAnswer.code) {
            setMessage(`Correct! It's ${country.name}`);
            sendEvent(pageName, "user_engagement", "correctAnswer", "button_press");
            onFlagClick();
        } else {
            setMessage(`Better luck next time. (It was ${quizData.rightAnswer.name})`);
            sendEvent(pageName, "user_engagement", "wrongAnswer", "button_press");
            onFlagClick();
            if (searchTerm) {
                setShowCountdown(true);
                setTimeout(() => {
                    window.location.href = `https://www.google.com/search?q=${searchTerm}`;
                    setShowCountdown(false);
                }, 3000);
            }
        }
    };

    if (!quizData) return null;

    return (
        <>
            <div style={{ borderWidth: '1px', borderColor: 'white' }}>
                <img src={`https://flagsapi.com/${quizData.rightAnswer.code}/flat/64.png`} alt="flag" 
                width={64}
                height={64}
                />
            </div>

            <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', borderWidth: '1px', borderColor: 'white' }}>
                <p style={{
                    marginBottom: '20px',
                    color: 'white',
                    fontSize: '1.5em',
                    fontWeight: 'bold'
                }}>
                    {question}
                </p>
                {!message ? (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
                        {options.map((option, index) => (
                            <button
                                key={index}
                                onClick={() => handleButtonClick(option, index)}
                                style={{
                                    padding: '10px 20px',
                                    fontSize: '1.2em',
                                    border: 'none',
                                    borderRadius: '25px',
                                    backgroundColor: '#a8a8a8',
                                    cursor: 'pointer',
                                    color: 'black',
                                    width: 'fit-content', // Adjusted width to fit the content
                                    height: '50px',
                                    textAlign: 'center',
                                    whiteSpace: 'normal', // Changed whiteSpace to normal
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    transition: 'background-color 0.3s ease',
                                }}
                                onMouseEnter={(e) => { e.target.style.backgroundColor = '#888888'; }}
                                onMouseLeave={(e) => { e.target.style.backgroundColor = '#a8a8a8'; }}
                            >
                                {option.name}
                            </button>
                        ))}
                    </div>
                ) : (
                    <>
                        <p style={{ marginTop: '20px', color: 'white', fontSize: '1.5em', fontWeight: 'bold' }}>{message}</p>
                        {showCountdown && <Countdown />}
                    </>
                )}
            </div>
        </>
    );
};

export default CountryFromFlagQuiz;
