import { addDoc, collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db, auth } from "../../firebase";

export const addFlagAnswer = async (gameId, correctAnswer, wasCorrect, wrongAnswer) => {
    try {
        // Only if logged in
        if (!auth.currentUser) return;
        await addDoc(collection(db, "games", gameId, "userAnswers"), {
            createdAt: serverTimestamp(),
            correctAnswer,
            wasCorrect,
            wrongAnswer,
            userId: auth.currentUser.uid,
        });

        console.log("Document written with ID: ");
    } catch (error) {
        console.error("Error adding document: ", error);
    }
};
