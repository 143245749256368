import './Home.css';

import React, { useRef, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import SearchBar from '../../components/SearchBar';
import CountryFromFlagQuiz from '../../components/flagQuiz/CountryFromFlagQuiz';
import FlagFromCountryQuiz from '../../components/flagQuiz/FlagFromCountryQuiz';
import PrivacyPolicy from '../../screens/appStack/Privacy-Policy';

import ReactGA from "react-ga4";
import Banner from '../../AsyncScriptLoader';

ReactGA.initialize('G-R9F5BN4NJ0');

const Home = () => {
    const searchInputRef = useRef(null);

    const onFlagClick = () => {
        searchInputRef.current.focus();
    };

    // Create an array of quizzes
    const quizzes = [<CountryFromFlagQuiz onFlagClick={onFlagClick} />, <FlagFromCountryQuiz onFlagClick={onFlagClick} />];

    // Choose a quiz randomly
    const [selectedQuiz] = useState(quizzes[Math.floor(Math.random() * quizzes.length)]);

    return (
        <>
            <div className="heading">
                Google
            </div>
            <SearchBar inputRef={searchInputRef} />

            <div className="container">
                {selectedQuiz}
            </div>
            {/* Make banner in center */}
            <div className="banner">
                <Banner />
            </div>
        </>
    );
};

export default Home;
