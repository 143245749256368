import React from "react";
// AuthStack
import Login from "./screens/authStack/Login";
import Register from "./screens/authStack/Register";

// AppStack
import Home from "./screens/appStack/Home";
import PrivacyPolicy from "./screens/appStack/Privacy-Policy";
import TermsAndConditions from "./screens/appStack/Terms-And-Conditions";

import { Route, Routes, Link } from "react-router-dom";

import useAuthStore from "./stores/authStore";
// import ProtectedRoute from "./navigation/ProtectedRoutes";
// import UnprotectedRoute from "./navigation/UnprotectedRoutes";

import './App.css'
import ServiceMenu from "./components/allPages/ServiceMenu";

// import SideMenu from "./components/allPages/SideMenu";
// import SiteHeader from "./components/allPages/SiteHeader";

function App() {
  const user = useAuthStore(state => state.user);

  return (
    <div className="app">
      <header className="app-header">
        <ServiceMenu />
      </header>
      {/* <SiteHeader mode={'light'} /> */}

      {/* {user && <SideMenu />} */}

      <Routes>
        {/* APPstack */}
        <Route path="/" element={
          // <ProtectedRoute>
          <Home />
          //  </ProtectedRoute> 
        } />

        <Route path="/privacy-policy" element={
          // <UnprotectedRoute>
          <PrivacyPolicy />
          //  </UnprotectedRoute>
        }
        />

        <Route path="/terms-and-conditions" element={
          // <UnprotectedRoute>
          <TermsAndConditions />
          //  </UnprotectedRoute>
        }
        />

        {/* AUTHstack */}
        <Route path="/login" element={
          // <UnprotectedRoute>
          <Login />
          //  </UnprotectedRoute> 
        } />

        <Route path="/register" element={
          // <UnprotectedRoute>
          <Register />
          // </UnprotectedRoute>
        }
        />
      </Routes>
      <footer className="footer">
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-and-conditions">Terms and conditions</Link>
      </footer>
    
    </div>
  );
}

export default App;
