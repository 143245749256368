import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Switch } from '@mui/material';
import useSearchStore from '../stores/useSearchStore';
import SearchIcon from '@mui/icons-material/Search';

import './SearchBar.css';

const SearchBar = ({ inputRef }) => {
    const [countdown, setCountdown] = useState(null);
    const searchTerm = useSearchStore(state => state.searchTerm);
    const setSearchTerm = useSearchStore(state => state.setSearchTerm);
    const { register, handleSubmit, setValue } = useForm();
    const [countdownActive, setCountdownActive] = useState(() => {
        const savedValue = localStorage.getItem("countdownActive");
        return savedValue !== null ? JSON.parse(savedValue) : true;
    });

    useEffect(() => {
        setValue('countdownActive', countdownActive);
    }, [countdownActive, setValue]);

    const onSubmit = data => {
        if (data.countdownActive) {
            setCountdown(5); // Set the countdown
            setTimeout(() => {
                window.location.href = `https://www.google.com/search?q=${searchTerm}`;
            }, 5000); // Delay the redirection by 5 seconds
        } else {
            window.location.href = `https://www.google.com/search?q=${searchTerm}`;
        }
    };

    useEffect(() => {
        let timerId;
        if (countdown > 0) {
            timerId = setInterval(() => {
                setCountdown(prevCount => prevCount - 1);
            }, 1000);
        }
        return () => {
            clearInterval(timerId); // Clear the interval when the component unmounts
        };
    }, [countdown]);

    useEffect(() => {
        inputRef.current.focus();
    }, [inputRef]);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    }

    return (

        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center', // Align the form itself in the center of this div
            width: '100%',
            color: 'white',
            backgroundColor: '#3c3c3c',
            marginBottom: '20px'
        }}>
            <form onSubmit={handleSubmit(onSubmit)} className='form' >

                <div style={{ position: 'relative', width: '100%', alignSelf: 'center' }}>
                    <input
                        type="text"
                        ref={inputRef}
                        placeholder="Search Google or type a URL"
                        value={searchTerm}
                        onChange={handleInputChange}
                        style={{
                            padding: '15px',
                            paddingLeft: '40px',
                            fontSize: '1.2em',
                            marginBottom: '10px',
                            width: '100%',
                            borderRadius: '25px',
                            border: 'none',
                        }}
                    />
                    <SearchIcon size={18} style={{ position: 'absolute', left: '10px', top: '42%', transform: 'translateY(-50%)', color: 'grey' }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginLeft: '10px' }}>
                        Countdown:
                    </label>
                    <Switch {...register('countdownActive')} checked={countdownActive}
                        onChange={() => {
                            setCountdownActive(!countdownActive);
                            localStorage.setItem("countdownActive", !countdownActive)
                        }}
                    />
                </div>
                {countdown && <p>You have {countdown} seconds to answer the question below.</p>}
            </form >
        </div>
    );
};

export default SearchBar;
