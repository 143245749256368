import React, { useState, useEffect } from 'react';

const Countdown = () => {
  const [count, setCount] = useState(3);

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [count]);

  return (
    <div
      style={{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: 'black',
        padding: '0.5rem 1rem',
        borderRadius: '5px',
      }}
    >
      {count}
    </div>
  );
};

export default Countdown;
